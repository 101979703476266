// --------------------------------------------------
// APP.JS
// --------------------------------------------------

//
// Initialize Foundation
// --------------------------------------------------

$(document).foundation();

//
// Custom JS
// --------------------------------------------------

$('header h1 span').addClass('preload');
$('header ul li a').wrapInner('<span class="preload"></span');
$('main h1').addClass('preload');
$('main h1 sup').addClass('preload');
$('main h2').wrapInner('<span class="preload"></span');
$('main h3').wrapInner('<span class="preload"></span');
$('main h4').wrapInner('<span class="preload"></span');
$('main h5').wrapInner('<span class="preload"></span');
$('main footer cite').wrapInner('<span class="preload"></span');
$('main a.button').wrapInner('<span class="preload"></span');
$('p').wrapInner('<span class="preload"></span');
$('main ul li').wrapInner('<span class="preload"></span');
$('#breadcrump a').addClass('preload');
$('#breadcrump span').addClass('preload');
$('#description').addClass('preload');


$(window).scroll(function (event) {
  var scroll = $(window).scrollTop();
 /* if ( scroll > 173 ) {
    $('header').addClass("compact")
  } else {
    $('header').removeClass("compact")
  }
  if ( scroll > 74 ) {
      $('#header_img').addClass("compact")
  } else if ( scroll < 120 ) {
      $('#header_img').removeClass("compact")
  }*/
});

$('#header_img').click(function() {
    $(this).toggleClass('fullsize');
    $('header').toggleClass('transparent');
    $('body').toggleClass('darken');
    $('#page').toggleClass('darken');
    $('main').toggleClass('transparent');
});

$('main').click(function() {
    $('#header_img').removeClass('fullsize');
    $('header').removeClass('transparent');
    $('body').removeClass('darken');
    $('#page').removeClass('darken');
    $('main').removeClass('transparent');
});





$( document ).ready(function() {

    $(window).on('load', function() {
        $('.preload').removeClass('preload');
        // $('.preload').addClass('loaded');
    });
});